<template>
  <div class="page">
    <About :about_str= "about_str" />
    <!-- <div id="about" class="back"  :style="'background-image: url('+ base_img +'back6.jpg'">
      <img aos="" :src="base_img + store_info.erweima">
      <p class="gujin">花样起名</p>
      <p class="tips">好听的名字，就像一幅山水画。</p>
    </div> -->
    <div class="foot">
      <button v-if="is_ios" class="black w1" @click="to_app_store">下载APP</button>
      <button v-else class="black w1" @click="go_to_xcx">打开小程序</button>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import About from '@/components/other/About'

export default {
  name: 'index_wx',
  store,
  components: {
    About
  },
  data () {
    return {
      base_img: store.state.base_img,
      about_str: '',
      is_ios: Utils.is_ios(),
      store_info: {
        erweima: ''
      }
    }
  },
  mounted () {
    this.index_wx()
  },
  methods: {
    index_wx () {
      axios.post('/index_wx/', { sid: this.$route.query.sid })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.store_info = res.data.data.store_info
          this.about_str = res.data.data.about_str
        })
    },
    to_app_store: function () {
      Utils.go_to_app('index_wx', '')
    },
    go_to_xcx: function () {
      // location.href = this.store_info.urlscheme
      Utils.go_to_xcx('to_xcx', this.$route.query.sid)
    }
  }
}
</script>
